import React from "react";

export default class SelectControl extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const {
      fieldOptions,
      fieldName,
      fieldClasses,
      fieldType,
      fieldTitle,
      fieldPlaceholder,
      fieldState,
      classNames,
      handleBlur,
      handleValueChange,
      showUISuccess,
      showUIError,
      labelClasses,
      hash,
    } = this.props;
    const options = fieldOptions.map((option, index) => (
      <option key={index} value={option.valueKey}>
        {option.valueText}{" "}
      </option>
    ));
    const classes = fieldClasses ? fieldClasses : "";

    return (
      <span
        className={classes + " giving-form__dropdown-wrapper input-group-addon"}
      >
        <select
          id={fieldName + "_" + hash}
          value={fieldState.value}
          name={fieldName}
          onBlur={handleBlur}
          onChange={handleValueChange}
          className={" btn giving-form__btn--dropdown widget-product"}
          aria-label="Donation options"
        >
          {options}
        </select>
      </span>
    );
  }
}
