import React from "react";
import ReactDOM from "react-dom";
import App from "./components/app.js";

// @TODO: update wvus-forms dependency to pull from master
if (window.donate_widgets) {
  window.donate_widgets.map(widget => {
    ReactDOM.render(
      <App data={widget} />,
      document.getElementById(
        "donation_widget_" + widget.id + "_" + widget.hash
      )
    );
  });
}
