import React from "react";
import { validateMinMaxNum } from "./lib/validation-helpers";
import FormMessage from "./form-message";
import { RadioControl, InputControl, validateIsInt } from "wvus-forms";
import { triggerAnalyticsEvent } from "./lib/ga-helper.js";

export default class WidgetPriceArrayForm extends React.Component {
  constructor(props) {
    super(props);

    this.widgetData = this.props.widgetData;

    this.priceArrayAmounts = this.widgetData.price_array_amounts
      .replace(/\s/g, "")
      .split(",");

    this.widgetOptions = {
      buttonText: this.widgetData.button_text
        ? this.widgetData.button_text
        : "Give Now",
      buttonColor: this.widgetData.button_color
        ? this.widgetData.button_color
        : "primary",
      widgetLayout: this.widgetData.layout,
      priceArrayOpenDollar: this.widgetData.price_array_open_dollar,
      priceArrayAmounts: this.priceArrayAmounts,
    };

    // For a price array, you should always only use the first product provided in the admin list
    this.product = this.widgetData.fields[0];
    this.productId = this.product.product_id;
    this.defaultPrice = this.product.price ? this.product.price : "";

    this.state = { gaTriggered: false };
  }

  componentDidMount() {
    const { formMethods } = this.props;

    formMethods.validateFields();

    if (!this.state.gaTriggered) {
      this.gaWidgetLoaded();
      this.setState({ gaTriggered: true });
    }
  }

  getFormValidation() {
    const { formMethods, widgetFormId } = this.props;

    const radioValid =
      formMethods.getFieldState("price_array_amount").value !==
      `unselected_${widgetFormId}`;

    const openDollarValid =
      this.widgetOptions.priceArrayOpenDollar === "1"
        ? formMethods.getFieldState("open_dollar_amount").isValid
        : true;

    return radioValid || openDollarValid;
  }

  getDonationAmt() {
    const { formMethods } = this.props;
    const value = formMethods.getFieldState("open_dollar_amount").value
      ? formMethods.getFieldState("open_dollar_amount").value
      : formMethods.getFieldState("price_array_amount").value;
    return value ? value.split("_", 1)[0] : "";
  }

  gaWidgetLoaded() {
    try {
      const product = this.widgetData.fields[0];
      const price = product.price;
      const item_id = product.sku;
      const item_name = product.product_name;
      const item_category = product.product_category;
      const item_variant = product.product_label;

      triggerAnalyticsEvent(
        {
          event_label: item_name,
          ecommerce: {
            items: [
              {
                item_brand: "non_sponsorship",
                item_category: item_category,
                item_id: item_id,
                item_variant: item_variant + ":" + price,
                item_name: item_name,
                price: price,
                quantity: "1",
              },
            ],
          },
          donate_widget_id: "DW1:" + this.widgetData.ID,
        },
        "donation_widget_loaded"
      );
    } catch (err) {
      // console.log('err', err);
    }
  }

  gaDonationAddToCart() {
    const product = this.widgetData.fields[0];
    const price = this.getDonationAmt();
    const item_id = product.sku;
    const item_name = product.product_name;
    const item_category = product.product_category;
    const item_variant = product.product_label;

    triggerAnalyticsEvent(
      {
        event_label: item_name,
        ecommerce: {
          items: [
            {
              item_brand: "non_sponsorship",
              item_category: item_category,
              item_id: item_id,
              item_variant: item_variant + ":" + price,
              item_name: item_name,
              price: price,
              quantity: "1",
            },
          ],
        },
        donate_widget_id: "DW1:" + this.widgetData.ID,
      },
      "donation_add_to_cart"
    );
  }

  getDonateUrl() {
    return (
      widget_donate_url_var.widget_donate_url +
      "/singlepagecheckout/cart/addProducts?productid[]=" +
      this.productId +
      "&amt[]=" +
      this.getDonationAmt()
    );
  }

  donateWidgetSubmit(e) {
    e.preventDefault();
    this.gaDonationAddToCart();
    window.location.assign(this.getDonateUrl());
  }

  getRadioControls() {
    const { formMethods, widgetFormId } = this.props;
    const amounts = this.widgetOptions.priceArrayAmounts;
    const formAmt = this.getDonationAmt();
    const mql = window.matchMedia("(max-width: 768px)").matches;

    return [
      amounts.map((amt, index) => {
        let labelClasses = "";
        if (formAmt === amt) {
          labelClasses = "is-selected";
        } else {
          labelClasses = "";
        }
        return (
          <RadioControl
            key={index}
            fieldId={amt + "_" + widgetFormId}
            fieldName="price_array_amount"
            fieldTitle={`$${amt}`}
            fieldCheckedDefault={
              index === 0 &&
              (this.widgetOptions.priceArrayOpenDollar === "0" ||
                this.defaultPrice === "")
            }
            formMethods={formMethods}
            isValid={true}
            fieldClasses={
              index > 1 &&
              mql &&
              this.widgetOptions.priceArrayOpenDollar === "1"
                ? "hidden"
                : ""
            }
            inputClasses="price-array__input"
            labelClasses={`price-array__option ${labelClasses}`}
            handleValueChange={this.handleRadioValueChange.bind(this)}
          />
        );
      }),
      <RadioControl
        key={"unselected"}
        fieldId={`unselected_${widgetFormId}`}
        fieldName="price_array_amount"
        fieldTitle={"unselected"}
        fieldCheckedDefault={
          this.widgetOptions.priceArrayOpenDollar === "1" &&
          this.defaultPrice !== ""
        }
        formMethods={formMethods}
        isValid={true}
        fieldClasses="hidden"
        handleValueChange={this.handleRadioValueChange.bind(this)}
      />,
    ];
  }

  handleRadioValueChange(e) {
    const { formMethods } = this.props;

    this.resetOpenDollarAmount();
    formMethods.setValueChange(e.target.name, e.target.id);
  }

  resetOpenDollarAmount() {
    if (this.widgetOptions.priceArrayOpenDollar !== "1") return;

    const { formMethods } = this.props;

    formMethods.setValueChange(
      "open_dollar_amount",
      "",
      formMethods.validateFields
    );
  }

  getInputControl() {
    const { formMethods } = this.props;
    const minPrice = parseInt(this.product.min_price);
    const maxPrice = parseInt(this.product.max_price);

    if (this.widgetOptions.priceArrayOpenDollar === "1") {
      return (
        <div className="array-open__wrapper">
          <span className="array-open__conjunction" />
          <div className="array-open__input-group input-group">
            <span className="input-group-addon">$</span>
            <InputControl
              fieldName="open_dollar_amount"
              fieldTitle="Other Donation Amount"
              fieldType="number"
              fieldClasses=""
              fieldValue={this.defaultPrice}
              secondInteraction={this.defaultPrice !== ""}
              isValid={true}
              formMethods={formMethods}
              optional={true}
              hideMessage={true}
              validators={[
                validateMinMaxNum(minPrice, maxPrice),
                validateIsInt,
              ]}
              handleBlur={this.handleInputBlur.bind(this)}
              handleValueChange={this.handleInputValueChange.bind(this)}
            />
          </div>
        </div>
      );
    }
  }

  handleInputBlur(e) {
    const { formMethods } = this.props;

    formMethods.handleBlur(e);
    formMethods.validateFields();
  }

  handleInputValueChange(e) {
    const { formMethods, widgetFormId } = this.props;

    formMethods.resetField("price_array_amount", `unselected_${widgetFormId}`);
    formMethods.handleValueChange(e, formMethods.validateFields);
  }

  render() {
    const { formMethods } = this.props;
    const submitDisabled = !this.getFormValidation();
    const openDollarClass = this.widgetOptions.priceArrayOpenDollar
      ? "price-array__wrapper--widget-open"
      : "";
    const inputState = formMethods.getFieldState("open_dollar_amount");
    const showError =
      inputState && typeof inputState.isValid !== "undefined"
        ? !inputState.isValid && inputState.secondInteraction
        : false;
    const errorMessage =
      inputState && inputState.errorMessage ? inputState.errorMessage : "";

    return (
      <div className={`price-array__wrapper--widget ${openDollarClass}`}>
        <div className="price-array">{this.getRadioControls()}</div>
        {this.getInputControl()}

        <button
          type="submit"
          className={"widget-button btn btn-" + this.widgetOptions.buttonColor}
          disabled={submitDisabled}
          onClick={this.donateWidgetSubmit.bind(this)}
          data-product-id={this.productId}
        >
          {this.widgetOptions.buttonText}
        </button>
        <FormMessage showError={showError} message={errorMessage} />
      </div>
    );
  }
}
