import React from "react";
import DonateWidgetForm from "./donate-widget-form";
import PriceArrayForm from "./price-array-widget-form";
import WVForm from "./form";
import { WVUSForm } from "wvus-forms";

export default class App extends React.Component {
  constructor(props) {
    super(props);

    const widgetDataKey = "donate_widget_data_" + this.props.data.id;
    this.widgetData = window[widgetDataKey];
  }

  render() {
    const WVPriceArrayForm = WVUSForm(PriceArrayForm);
    return (
      <form className="giving-form form-inline">
        {this.widgetData.price_array_selected === "1" ? (
          <WVPriceArrayForm
            {...this.props}
            widgetData={this.widgetData}
            widgetFormId={this.props.data.id}
          />
        ) : (
          <WVForm {...this.props} innerForm={DonateWidgetForm} />
        )}
      </form>
    );
  }
}
