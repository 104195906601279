import React from "react";

export default ({ showError, showSuccess, message }) => {
  const icon = showError ? (
    <i className="fa fa-exclamation-circle" />
  ) : showSuccess ? (
    <i className="fa fa-check-circle" />
  ) : null;
  return (
    <span className="message">
      {icon}
      <span data-field-validation-message> {message}</span>
    </span>
  );
};
