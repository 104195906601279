import React from "react";
import {
  createValidationHelper,
  validateRequired,
  validateEmpty,
  validateEmail,
  validateMin,
  validateMinMaxNum,
} from "./lib/validation-helpers.js";
import InputControl from "./widget-input-control.js";
import SelectControl from "./widget-select-control.js";
import Message from "./message.js";
import { triggerAnalyticsEvent } from "./lib/ga-helper.js";

export default class DonateWidgetForm extends React.Component {
  constructor(props) {
    super(props);

    this.widgetData = this.props.data.id;
    const widgetDataKey = "donate_widget_data_" + this.widgetData;

    this.products = window[widgetDataKey].fields.map((field) => {
      return {
        valueKey: field.product_id.toString(),
        valueText: field.product_label,
      };
    });

    this.productList = window[widgetDataKey].fields;

    this.state = {
      fields: {
        widget_price: {
          value: "",
          secondInteraction: false,
          isValid: false,
          errorMessage: "",
        },
        widget_product: {
          value: "",
          secondInteraction: false,
          isValid: true,
          errorMessage: "",
        },
      },
      formValid: false,
      errorMessage: "",
      successMessage: "",
      buttonText: window[widgetDataKey].button_text
        ? window[widgetDataKey].button_text
        : "Give Now",
      buttonColor: window[widgetDataKey].button_color
        ? window[widgetDataKey].button_color
        : "primary",
      widgetLayout: window[widgetDataKey].layout,
      gaTriggered: false,
    };

    // Register and Bind FormMethods from form wrapper
    this.bindLocalMethods = props.bindLocalMethods.bind(this);
    this.bindLocalMethods();
    this.handleNextStep = props.handleNextStep;
    this.handleProductChange = this.handleProductChange.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);
  }

  componentDidMount() {
    if (!this.state.gaTriggered) {
      this.gaWidgetLoaded();
      this.setState({ gaTriggered: true });
    }
  }

  UNSAFE_componentWillMount() {
    const defaultProduct = this.productList.filter(
      (product) => product.default_product === "true"
    )[0];
    this.setProductValidation(defaultProduct);
    this.setProduct(defaultProduct.product_id);
    this.setValueChange("widget_price", defaultProduct.price);
  }

  showSuccessState(fieldState) {
    return fieldState.isValid;
  }

  showErrorState(fieldState) {
    return !fieldState.isValid && fieldState.secondInteraction;
  }

  getDonateUrl() {
    return (
      widget_donate_url_var.widget_donate_url +
      "/singlepagecheckout/cart/addProducts?productid[]=" +
      this.state.fields.widget_product.value +
      "&amt[]=" +
      this.state.fields.widget_price.value
    );
  }

  gaWidgetLoaded() {
    try {
      const product = this.productList[0];
      const price = product.price;
      const item_id = product.sku;
      const item_name = product.product_name;
      const item_category = product.product_category;
      const item_variant = product.product_label;
      const dataWidgetID = this.props.data.id;

      triggerAnalyticsEvent(
        {
          event_label: item_name,
          ecommerce: {
            items: [
              {
                item_brand: "non_sponsorship",
                item_category: item_category,
                item_id: item_id,
                item_variant: item_variant + ":" + price,
                item_name: item_name,
                price: price,
                quantity: "1",
              },
            ],
          },
          donate_widget_id: "DW1:" + dataWidgetID,
        },
        "donation_widget_loaded"
      );
    } catch (err) {
      // console.log('err', err);
    }
  }

  gaDonationAddToCart() {
    const product = this.productList[0];
    const price = product.price;
    const item_id = product.sku;
    const item_name = product.product_name;
    const item_category = product.product_category;
    const item_variant = product.product_label;
    const dataWidgetID = this.props.data.id;

    triggerAnalyticsEvent(
      {
        event_label: item_name,
        ecommerce: {
          items: [
            {
              item_brand: "non_sponsorship",
              item_category: item_category,
              item_id: item_id,
              item_variant: item_variant + ":" + price,
              item_name: item_name,
              price: price,
              quantity: "1",
            },
          ],
        },
        donate_widget_id: "DW1:" + dataWidgetID,
      },
      "donation_add_to_cart"
    );
  }

  donateWidgetSubmit(e) {
    e.preventDefault();
    this.gaDonationAddToCart();
    window.location.href = this.getDonateUrl();
  }

  setProduct(productId) {
    let selectedProduct = {};
    // Loop over each product to find the selected product, also create a list of the default prices
    const productDefaultPrices = this.productList.map((product) => {
      if (product.product_id === productId) {
        selectedProduct = product;
      }
      return parseInt(product.price);
    });

    const selectedProductPrice = parseInt(selectedProduct.price);
    const priceFieldValue = parseInt(this.state.fields.widget_price.value);

    if (productDefaultPrices.indexOf(priceFieldValue) !== -1) {
      // Default prices are still active, so change to the selected products price
      this.setValueChange("widget_price", selectedProduct.price);
    } else if (
      selectedProductPrice !== priceFieldValue &&
      priceFieldValue < parseInt(selectedProduct.min_price)
    ) {
      // The user has changed the field, let's validate the selected product's min price and the price the user inputted.
      // Set the price to the min price for the selected product
      this.setValueChange("widget_price", selectedProduct.min_price);
    }

    this.setProductValidation(selectedProduct);
    this.setValueChange("widget_product", productId);
  }

  setProductValidation(product) {
    const validationConfig = {
      widget_price: {
        validators: [
          validateRequired,
          validateMinMaxNum(
            parseInt(product.min_price),
            parseInt(product.max_price)
          ),
        ],
      },
      widget_product: {
        validators: [],
      },
    };

    this.validationHelper = createValidationHelper(validationConfig);
  }

  handleProductChange(e) {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;

    this.setProduct(fieldValue);
  }

  handlePriceChange(e) {
    const price = String(parseInt(e.target.value));
    this.setValueChange("widget_price", price);
  }

  generateHash() {
    return Math.random().toString(36).substring(7);
  }

  render() {
    const hash = this.generateHash();
    const select =
      this.productList.length <= 1 ? (
        ""
      ) : (
        <SelectControl
          classNames="state_select input-control"
          fieldOptions={this.products}
          fieldName="widget_product"
          fieldClasses="widget-product"
          fieldTitle=""
          fieldState={this.state.fields.widget_product}
          handleBlur={this.handleBlur}
          handleValueChange={this.handleProductChange}
          fieldPlaceholder=""
          showUISuccess={this.showUISuccess(
            this.state.fields["widget_product"]
          )}
          showUIError={this.showUIError(this.state.fields["widget_product"])}
          hash={hash}
        />
      );
    const noDropdown = this.productList.length <= 1 ? "no-dropdown" : "";
    return (
      <div className={this.state.widgetLayout}>
        <div className="input-group">
          <span className={"giving-form__wrapper-amount " + noDropdown}>
            <InputControl
              fieldName="widget_price"
              fieldTitle=""
              fieldClasses=""
              fieldState={this.state.fields["widget_price"]}
              handleBlur={this.handleBlur}
              handleValueChange={this.handlePriceChange}
              fieldType="number"
              fieldPlaceholder=""
              showUISuccess={this.showUISuccess(
                this.state.fields["widget_price"]
              )}
              showUIError={this.showUIError(this.state.fields["widget_price"])}
              hash={hash}
            />

            {select}
          </span>

          <div className="input-group-btn giving-form_wrapper-cta">
            <button
              type="submit"
              className={
                "widget-button btn giving-form__btn--" + this.state.buttonColor
              }
              disabled={!this.state.formValid}
              onClick={this.donateWidgetSubmit.bind(this)}
              data-product-id={this.state.fields.widget_product.value}
            >
              {this.state.buttonText}
            </button>
          </div>
        </div>
        <div className="donate-message">
          {Object.keys(this.state.fields).map((fieldname) =>
            this.showUIError(this.state.fields[fieldname]) ? (
              <p key={fieldname} className="has-error">
                <Message
                  key={fieldname}
                  showError={true}
                  showSuccess={false}
                  message={this.state.fields[fieldname].errorMessage}
                />{" "}
              </p>
            ) : (
              ""
            )
          )}
        </div>
      </div>
    );
  }
}
