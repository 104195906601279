import React from "react";

export default ({
  fieldId,
  fieldName,
  fieldClasses,
  fieldType,
  fieldTitle,
  fieldPlaceholder,
  fieldState,
  handleValueChange,
  handleBlur,
  classNames,
  showUISuccess,
  showUIError,
  hash,
}) => {
  return (
    <div
      className={fieldClasses + " input-group "}
      data-field-container={fieldId}
      data-field-is-valid={fieldState.isValid}
    >
      <span className="giving-form__currency">$</span>
      <input
        id={fieldName + "_" + hash}
        value={fieldState.value}
        name={fieldName}
        onBlur={handleBlur}
        onChange={handleValueChange}
        className="giving-form__form-control form-control widget-price"
        type={fieldType}
        placeholder={fieldPlaceholder}
        size="6"
        aria-label="Donation amount in dollars"
      />
    </div>
  );
};
